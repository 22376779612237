import { now } from '@/@core/utils/filter'
import { downloadTangibleReport, fetchTangiblePurchasesSummary, fetchTangibleReports, storeTangibleReport } from '@/api/reporting'
import { notifyErrors, withOptions } from '@/assets/js/utils'
import mStore from '@/modules/merchant/store'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

const cached = []

export default function useTangibleReport() {
  const tangibleSummary = ref([])

  const tangibleSummaryColumns = [
    { text: 'ORDER NUMBER', value: 'order_number', sortable: false },
    { text: 'ORDER DATE', value: 'order_date', sortable: false },
    { text: 'MERCHANT', value: 'merchant_name', sortable: false },
    { text: 'SKU', value: 'sku', sortable: false },
    { text: 'FIRST NAME', value: 'first_name', sortable: false },
    { text: 'NAME NAME', value: 'last_name', sortable: false },
    { text: 'EMAIL', value: 'email', sortable: false },
    { text: 'CONTACT PHONE', value: 'contact_number', sortable: false },
    { text: 'ADDRESS', value: 'address', sortable: false },
    { text: 'POSTAL', value: 'postal_code', sortable: false },
  ]

  const tangibleReports = ref([])
  const tangibleReportsTotal = ref(0)
  const tangibleReportsColumns = [
    { text: 'STATUS', value: 'status', sortable: false },
    { text: 'FROM', value: 'report_from_date', sortable: false },
    { text: 'TO', value: 'report_to_date', sortable: false },
    { text: 'COUNT', value: 'count' },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'SENT AT', value: 'sent_at' },
    { text: 'ACTIONS', value: 'actions' },
  ]

  const loading = ref(false)

  const options = ref(
    cached[0] || {
      sortBy: ['created_at'],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1,
    },
  )

  const form = ref(
    cached[1] || {
      from: now().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
      to: now().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
    },
  )

  const merchantId = computed(() => mStore.state.current?.id || undefined)

  // const cachable = [form, merchantId]

  // const cache = () => {
  //   cachable.forEach((variable, index) => {
  //     cached[index] = variable.value
  //   })
  // }

  const loadTangiblesSummary = () =>
    fetchTangiblePurchasesSummary({
      merchant_id: merchantId.value,
      created_at_from: now(form.value.from).startOf('day').toString(),
      created_at_to: now(form.value.to).endOf('day').toString(),
    }).then(({ data }) => {
      tangibleSummary.value = data.data.items
    })

  const loadTangibleReports = () =>
    fetchTangibleReports(
      withOptions(options.value, {
        merchant_id: merchantId.value,
        report_from_date: now(form.value.from).startOf('day').toString(),
        report_to_date: now(form.value.to).endOf('day').toString(),
      }),
    ).then(({ data }) => {
      tangibleReports.value = data.data.records
      tangibleReportsTotal.value = data.data.pagination.total
    })

  const generateTangibleReport = async () => {
    loading.value = true

    const res = await storeTangibleReport({
      merchant_id: merchantId.value,
      created_at_from: now(form.value.from).startOf('day').toString(),
      created_at_to: now(form.value.to).endOf('day').toString(),
    })
      .then(loadTangibleReports)
      .catch(err => {
        notifyErrors(err)
      })

    loading.value = false

    return res
  }

  const reloadAll = async () => {
    loading.value = true
    options.value.page = 1
    try {
      await loadTangiblesSummary()
      await loadTangibleReports()
    } catch (err) {
      notifyErrors(err)
    }
    loading.value = false
  }

  const cachable = [options, form, merchantId]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, async () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    await loadTangibleReports()
    loading.value = false
    cache()
  })

  onMounted(() => {
    reloadAll()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tangibleSummary,
    tangibleSummaryColumns,
    tangibleReports,
    tangibleReportsTotal,
    tangibleReportsColumns,

    loading,
    options,
    form,
    reloadAll,
    generateTangibleReport,
    downloadTangibleReport,
  }
}
